import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function HomePage() {
    const location = useLocation();

    useEffect(() => {
      const hash = location.hash;
      if (hash) {
        const element = document.getElementById(hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [location]);

    return (
        <body>
            <header>
                <div class="container">
                    <div class="header-container">
                        <div class="logo">
                            <a href="#" style={{ textDecoration: "none" }}>
                                <h2>Elias Collinson</h2>
                            </a>
                        </div>
                        <nav>
                            <ul class="nav-menu">
                                <li><a href="#projects">Projects</a></li>
                                <li><Link to={"/resume"}>Resume</Link></li>
                                <li>
                                    <div class="contact-button">
                                        <a href="mailto: eliascollinson@gmail.com">Contact</a>
                                    </div>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </header>

            <section class="hero">
                <div class="container">
                    <div class="hero-container">
                        <div class="hero-text">
                            <h1>Welcome to My Portfolio Website</h1>
                            <p>My name is Elias Collinson and I'm a software developer.</p>
                        </div>
                        <div class="hero-image">
                            <img src='my_pic3.jpeg' alt="Me" style={{ maxWidth: "650px" }}></img>
                        </div>
                    </div>
                </div>
            </section>

            <section class="technologies">
                <div class="container">
                    <h2>Creating software using multiple languages and technologies</h2>
                    <p>Here's a few I use most often</p>
                    <div class="technologies-grid">
                        <div class="technologies-item">
                            <img src="CSharp.png" alt="Icon 1"></img>
                            <h3>C#</h3>
                            <p>C# is one of my most proficient languages I've used to create multiple applications.</p>
                        </div>
                        <div class="technologies-item">
                            <img src="WebIcons.png" alt="Icon 2"></img>
                            <h3>Html, CSS, and JavaScript</h3>
                            <p>I've used these technologies to create many websites, such as the one you're using right now.</p>
                        </div>
                        <div class="technologies-item">
                            <img src="SQL.png" alt="Icon 3"></img>
                            <h3>Structured Query Language</h3>
                            <p>I've created and managed databases using SQL for many projects.</p>
                        </div>
                    </div>
                    <div class="feature-text">
                        <h2>Want to learn More?</h2>
                        <p>Take a look at my resume</p>
                        <Link id='button' to={"/resume"}>Resume</Link>
                    </div>
                </div>
            </section>

            <section class="projects" id="projects">
                <div class="container">
                    <h2>Previous Projects</h2>
                    <p style={{ textAlign: "center" }}>Here's a few projects I've worked on in the past</p>
                    <div class="content-section">
                        <div class="content-box">
                            <h3>CapsTory Inventory Management</h3>
                            <div class="project-content">
                                <img src="CapsTory.png" alt="CapsTory"></img>
                                <p>A cross-platform inventory management application for use on both mobile and desktop. Built
                                    for warehouse or store use. This project involved creating a web app for desktop use,
                                    utilizing MAUI to create an Android and IOS app, and creating an API to connect both apps to
                                    a database.</p>
                            </div>
                        </div>
                        <hr style={{ height: "1px", width: "50%", border: "0", background: "#ccc" }}></hr>
                        <div class="content-box">
                            <h3>TaskMaster Task Manager </h3>
                            <div class="project-content" id="rev">
                                <p>A web application designed to help users manage tasks and projects in their daily lives.
                                    Users can group tasks, assign due dates and labels to them, and set their completion status.
                                </p>
                                <img src="TaskMaster.png" alt="TaskMaster"></img>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footer>
                <div class="container">
                    <div class="footer-links">
                        <a href="mailto: eliascollinson@gmail.com">Contact</a>
                    </div>
                    <div class="footer-info">
                        <p>© 2024 Elias Collinson. All rights reserved.</p>
                    </div>
                </div>
            </footer>
        </body>

    );
}

export default HomePage;