import { Link } from 'react-router-dom';

function ResumePage() {
    return (
        <body>
            <header>
                <div class="container">
                    <div class="header-container">
                        <div class="logo">
                        <Link to={"/"} style={{textDecoration: "none"}}><h2>Elias Collinson</h2></Link>
                        </div>
                        <nav>
                            <ul class="nav-menu">
                                <li><Link to={"/#projects"}>Projects</Link></li>
                                <li><a href="#">Resume</a></li>
                                <li>
                                    <div class="contact-button">
                                        <a href="mailto: eliascollinson@gmail.com">Contact</a>
                                    </div>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </header>

            <section class="resume">
                <div class="container">
                    <div class="resume-container">
                        <h1 style={{textAlign: "center"}}>My Resume</h1>
                        <embed src="Resume.pdf" type="application/pdf" width="90%" height="800px" />
                    </div>
                </div>
            </section>

            <footer>
                <div class="container">
                    <div class="footer-links">
                        <a href="mailto: eliascollinson@gmail.com">Contact</a>
                    </div>
                    <div class="footer-info">
                        <p>© 2024 Elias Collinson. All rights reserved.</p>
                    </div>
                </div>
            </footer>
        </body>
    );
}

export default ResumePage;